<template>
  <div class="ai_chat">
    <WordDictM class="d-lg-none" v-if="dickData" v-on:closeDict="closeDickALL" :content="dickData.ductWord" />
    <div class="d-flex flex-wrap">
      <div class="swiper_item">
        <div class="slider-banner position-relative">
          <swiper :options="bannerOption" class="swiper" ref="mySwiper" @slideChange="onSlideChange">
            <swiper-slide v-show="isBegin" class="swiper-item">
              <Orally_speaking_header :isBegin="isBegin" :selectedStep="0" v-on:reStart="reStart" />

              <div class="chat-content bg-white p-3 w-100 smooth-scroll" ref="chatContent">
                <div v-if="chatObj.length > 0" >
                  <div v-for="(item, index) in chatObj" :key="index">
                    <RobotDialogue :isSelectedType="false" :itemData="item" :isBegin="isBegin" :selectedStep="selectedStep" class="mb-3" v-if="item.role === 'robot'" v-on:selectAnswer="selectAnswer" v-on:selectType="selectType" />
                    <SelfDialogue :itemData="item" class="mb-3" v-if="item.role === 'self'" />
                    <RobotDialogue :isSelectedType="true" :selectedAns="selectedAns" :selectedAns2="selectedAns2" v-if="index === chatObj.length - 1 && (selectedAns && selectedAns2)" v-on:selectType="selectType" />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide v-show="!isBegin" class="swiper-item">
              <Orally_speaking_header :isBegin="isBegin" :selectedStep="0" v-on:reStart="reStart" :selectedAns="selectedAns" :selectedAns2="selectedAns2"  />

              <div class="chat-content bg-white p-3 w-100 smooth-scroll" ref="chatContent1">
                <div v-if="follow_upObj.length > 0">
                  <div v-for="(item, index) in follow_upObj" :key="index">
                    <RobotDialogue :selectedStep="0" :itemData="item" class="mb-3" v-if="item.role === 'robot'" />
                    <SelfDialogue :selectedStep="0" :itemData="item" class="mb-3" v-if="item.role === 'self'" />
                  </div>
                </div>
              </div>

              <div class="follow-btn d-flex justify-content-center w-100 align-items-center text-lg">
                <a @click="generateSentence" class="pointer flex-1 d-flex bg-chat-purple font-bold text-white justify-content-center align-items-center">
                  <img src="@/assets/image/ai/icon_re.png" alt="" class="mr-1"><p>繼續練習 Continue</p>
                </a>
                <a @click="toSlide(1)" class="pointer flex-1 d-flex bg-chat-green font-bold text-white justify-content-center align-items-center py-1">
                  <p>對話練習 Conversation</p><img src="@/assets/image/ai/arrow_R.png" alt="" class="ml-1">
                </a>
              </div>
            </swiper-slide>
            <swiper-slide v-show="!isBegin" class="swiper-item">
              <div class="w-100 position-relative d-flex">
              <div class="w-100">
                <Orally_speaking_header :isBegin="isBegin" :selectedStep="1" v-on:reStart="reStart" :selectedAns="selectedAns" :selectedAns2="selectedAns2" />

                <div class="chat-content bg-white p-3 w-100 smooth-scroll" ref="chatContent2">

                  <div v-if="conversationObj.length > 0">
                    <div v-for="(item, index) in conversationObj" :key="index">
                      <RobotDialogue :chatHistory="chatHistory[index]" :itemIdx="index" :selectedStep="1" :itemData="item" class="mb-3" v-if="item.role === 'robot'" v-on:endTyped="scrollToBottom" />
                      <SelfDialogue :chatHistory="chatHistory[index]" :theme="theme" :context="context" :selectedStep="1" :itemData="item" class="mb-3" v-if="item.role === 'self'" />
                    </div>
                  </div>
                </div>

                <div class="chat-button d-flex justify-content-center w-100 align-items-center">
                  <a class="ai-suggest-button pointer tooltip-outer d-none d-lg-inline-block" @click="toggleAISuggest()" v-if="isOpenKeyboard" :class="{ 'opacity-50': !lastChatResult || remainPoints < 1 }">
                    <img src="@/assets/image/ai/AI_A.png" alt="" class="w-100 h-auto d-inline-block" v-if="!isAiSuggest">
                    <img src="@/assets/image/ai/AI_A_active.png" alt="" class="w-100 h-auto d-inline-block" v-if="isAiSuggest">
                    <span class="tooltiptext">AI代答</span>
                  </a>
                  <SpeechRecognition v-on:speechCountDown="speechCountDown" v-on:emitSpeechRecognition="emitSpeechRecognition" v-if="isOpenKeyboard" />

                  <div class="chat-button-input d-flex align-items-center flex-1 p-2 pr-4 bg-white" v-if="isOpenKeyboard">
                    <a v-if="!isSpeechRecognition" class="keyboard-btn notOpenKeyboard d-block mr-2 p-2 px-3">
                      <img src="@/assets/image/ai/notOpenKeyboard.png" alt="" class="">
                    </a>
                    <p class="mr-2 p-2 text-sm" v-if="isSpeechRecognition">{{ speechCount }}s</p>
                    <input ref="input3" :disabled="remainPoints < 1" type="text" class="w-100 border-0 focus:outline-none" v-model="selfText" @keyup.enter="inputText3" placeholder="請點「麥克風」開始錄音應答，或點「AI」生成回應建議。">
                    <a class="pointer ml-2 tooltip-outer d-inline-block" @click="inputText3()" :class="{ 'opacity-50': remainPoints < 1 }"><img src="@/assets/image/ai/submit_icon.png" alt="" class="">
                      <span class="tooltiptext">送出</span>
                    </a>
                  </div>

                </div>
                <div class="d-flex aiSuggestDialogue">
                  <a class="mr-1 d-mr-2 ai-suggest-button pointer tooltip-outer d-lg-none" @click="toggleAISuggest()" v-if="isOpenKeyboard" :class="{ 'opacity-50': !lastChatResult || remainPoints < 1 }">
                    <img src="@/assets/image/ai/AI_A.png" alt="" class="w-100 h-auto d-inline-block" v-if="!isAiSuggest">
                    <img src="@/assets/image/ai/AI_A_active.png" alt="" class="w-100 h-auto d-inline-block" v-if="isAiSuggest">
                    <span class="tooltiptext">AI代答</span>
                  </a>

                  <AiSuggestDialogue :session_id="session_id" :theme="theme" :context="context" :context2="context2" :lastChatResult="lastChatResult" class="pt-1 pb-4 flex-1" v-if="isOpenKeyboard && isAiSuggest" />
                </div>
                <!-- <AiSuggestDialogue :session_id="session_id" :theme="theme" :context="context" :context2="context2" :lastChatResult="lastChatResult" class="mt-2" v-if="isOpenKeyboard && isAiSuggest" /> -->

              </div>

            </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div class="swiper_rule">
        <Rule />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/store/api'
import Orally_speaking_header from '@/components/AiHelper/chat/orally_speaking_header'
import RobotDialogue from '@/components/AiHelper/chat/robotDialogue'
import SelfDialogue from '@/components/AiHelper/chat/selfDialogue'
import AiSuggestDialogue from '@/components/AiHelper/chat/aiSuggestDialogue'
import SpeechRecognition from '@/components/AiHelper/chat/SpeechRecognition'
import allJs from '@/components/AiHelper/all.js'
import Rule from '@/components/AiHelper/rule.vue'
import Bus from '@/components/AiHelper/bus.js'
import WordDictM from '@/components/AiHelper/WordDictM'
import { mapGetters } from 'vuex'
export default {
  name: 'chat_ai',
  components: {
    RobotDialogue,
    SelfDialogue,
    AiSuggestDialogue,
    Orally_speaking_header,
    SpeechRecognition,
    Rule,
    WordDictM
  },
  data () {
    return {
      chatJSon: null,
      selectedStep: 0,
      isOpenKeyboard: true,
      isTips: false,
      isDictionary: false,
      isBegin: true,
      isAiSuggest: false,

      isDisbaledInput: true,

      recorder: null,
      playTime: 0,
      timer: null,
      src: null,
      isRecorder: false,

      session_id: null,

      selfText: '',

      selectedAns: null,
      selectedAns2: null,

      chatObj: [],

      follow_upObj: [],

      conversationObj: [],

      element2OffsetHeight: null,

      speechCount: null,
      isSpeechRecognition: false,

      carousel_left: 0,

      lastChatResult: null,

      bannerOption: {
        slidesPerView: 1,
        centeredSlides: true,
        watchOverflow: true,
        loop: false,
        allowTouchMove: false,
        effect: 'slide'
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev'
        // }
      },

      isGettingChatsCreate: false,

      chatHistory: [],

      audio_id: '',

      dickData: null,

      prompt_id: null
    }
  },
  mixins: [
    allJs
  ],
  computed: {
    ...mapGetters([
      'remainPoints'
    ]),
    theme () {
      let self = this
      if (self.selectedAns && self.selectedAns2 && self.selectedStep === 0) {
        return self.selectedAns2.theme
      } else if (self.selectedAns && self.selectedAns2 && self.selectedStep === 1) {
        return self.selectedAns2.theme
      }
      return ''

    },
    context () {
      let self = this
      if (self.selectedAns && self.selectedAns2 && self.selectedStep === 0) {
        return self.selectedAns2.context
      } else if (self.selectedAns && self.selectedAns2 && self.selectedStep === 1) {
        return self.selectedAns2.context
      }
      return ''
    },
    context2 () {
      let self = this
      if (self.selectedAns && self.selectedAns2 && self.selectedStep === 0) {
        return self.selectedAns2.context2
      } else if (self.selectedAns && self.selectedAns2 && self.selectedStep === 1) {
        return self.selectedAns2.context2
      }
      return ''
    },
    swiper () {
      return this.$refs.mySwiper.swiper
    }
  },
  created () {
    this.getCategories()
  },
  mounted () {
    this.getBus()
  },
  watch: {
    'conversationObj': {
      handler (newVal, oldVal) {
        let self = this
        if (newVal !== oldVal) {
          self.scrollToBottom()
        }
      },
      deep: true,
      immediate: true
    },
    'selfText': {
      handler (newVal, oldVal) {
        let self = this
        if (newVal !== oldVal && newVal) {
          self.selfText = newVal.replace(/[^\w\s',\uFF0C\u002E\u003F\uFF1A\u003A\/\-\!]/g, '')
        }
      },
      immediate: true
    },
  },
  updated () {
    this.scrollToBottom()
  },
  methods: {
    closeDickALL () {
      this.dickData = null
      Bus.$emit('closeDickALL')
    },
    getCategories () {
      // 場景與動作
      let self = this
      let prompt_id = self.$route.params.promptId
      // let path = `/ai/categories`
      let path = `/ai/categories/${prompt_id}`

      let obj = { role: 'robot', text: '請選擇主題，來場零壓力的互動對話練習吧！', options: null, isLoading: true }
      self.chatObj.push(obj)

      const options = { headers: {'showLoading': false }}

      api.get(path, options)
      .then((res) => {
        self.prompt_id = res.data.prompt_id
        self.chatJSon = res.data.data

        let index = self.chatObj.findIndex(x => x.isLoading === true)

        self.chatObj[index].isLoading = false
        self.chatObj[index].options = res.data.data
      })
      .catch((err) => {
        console.log(err)
      })
    },

    emitSpeechRecognition (emitSpeechRecognition) {
      let self = this

      if (emitSpeechRecognition) {
        self.selfText = self.selfText + emitSpeechRecognition.result

        self.audio_id = emitSpeechRecognition.id

        self.$refs.input3.focus()
      }
    },

    // emitSpeechRecognition2 (emitSpeechRecognition2) {
    //   let self = this
    //   if (emitSpeechRecognition2) {
    //     self.selfText = self.selfText + emitSpeechRecognition2.result

    //     self.audio_id = emitSpeechRecognition2.id
    //   }
    //   if (self.isBegin) {
    //     self.inputText()
    //   } else {
    //     self.inputText3()
    //   }
    // },

    async chatsCreate () {
      // 會話開始
      let self = this

      let path = `/ai/chats/create/?uuid=${self.prompt_id}`

      let obj = { role: 'robot', text: '', ttsUrl: null, translate: null, isLoading: true }
      self.conversationObj.push(obj)

      const options = { headers: {'showLoading': false }}
      self.isGettingChatsCreate = true
      await api.post(path, {
        theme: self.theme,
        context: self.context
      }, options)
      .then((res) => {
        self.$store.dispatch('getRemainPoints')
        self.session_id = res.data.session

        self.setDataWithPractice(res.data.result)

        self.isBegin = false
        self.selectedStep = 1

        self.isGettingChatsCreate = false
      })
      .catch((err) => {
        console.log(err)
      })
    },

    generateSentence () {
      // 實用句
      let self = this

      let path = `/ai/generate/sentence`

      let obj = { role: 'robot', text: '', ttsUrl: null, translate: null, isLoading: true }
      self.follow_upObj.push(obj)
      const options = { headers: {'showLoading': false }}
      api.post(path, {
        theme: self.theme,
        context: self.context
      }, options)
      .then((res) => {
        self.$store.dispatch('getRemainPoints')
        self.setDataWithFollow(res.data.result)
          self.$nextTick(() => {
          self.scrollToBottom()
        })
      })
      .catch(() => {
      })
    },

    chatsContinue (text) {
      // 繼續對話
      let self = this

      let path = `/ai/chats/${self.session_id}?uuid=${self.prompt_id}`

      let obj = { role: 'robot', text: '', ttsUrl: null, translate: null, isLoading: true }
      self.conversationObj.push(obj)
      const options = { headers: {'showLoading': false }}
      api.post(path, {
        content: text,
        audio: self.audio_id
      }, options)
      .then((res) => {
        self.$store.dispatch('getRemainPoints')
        self.setDataWithPractice(res.data.result)

        self.audio_id = ''
      })
      .catch(() => {
        self.conversationObj.pop()
      })
    },

    getHistory () {
      let self = this
      let path = `ai/chats/${self.session_id}/history`
      const options = { headers: {'showLoading': false }}
      api.get(path, options)
      .then((res) => {
        self.chatHistory = res.data.records
      })
      .catch((err) => {
        console.log(err)
      })
    },

    async setDataWithFollow (result) {
      let self = this

      let index = self.follow_upObj.findIndex(x => x.isLoading === true)

      self.follow_upObj[index].isLoading = false
      self.follow_upObj[index].text = result
      self.follow_upObj[index].ttsUrl = await this.getTTSUrl('female', result)
      self.follow_upObj[index].translate = null
    },

    async setDataWithPractice (result) {
      let self = this

      let index = self.conversationObj.findIndex(x => x.isLoading === true)

      self.conversationObj[index].isLoading = false
      self.conversationObj[index].text = result
      self.conversationObj[index].ttsUrl = await self.getTTSUrl('female', result)
      self.conversationObj[index].translate = await self.get_Translate_Dialog(self.theme, self.context, result)

      self.lastChatResult = result

      self.$nextTick(() => {
        self.scrollToBottom()
        self.getHistory()
      })
    },

    reStart () {
      this.isBegin = true
      this.selectedStep = 0
      this.isAiSuggest = false

      this.selectedAns = null
      this.selectedAns2 = null

      this.chatObj = this.$options.data().chatObj
      this.follow_upObj = []
      this.conversationObj = this.$options.data().conversationObj

      this.$forceUpdate()
      this.getCategories()

      this.$store.dispatch('setPlayBackRate', 1)
      this.$store.dispatch('toggleSubtitle', true)
    },

    scrollToBottom () {
      let self = this
      if (self.chatObj.length > 0 || self.conversationObj.length > 0) {
        const element = self.$refs.chatContent
        const element1 = self.$refs.chatContent1
        const element2 = self.$refs.chatContent2

        if (element && element.scrollHeight > element.offsetHeight) {
          element.scrollTop = element.scrollHeight
        }
        if (element1 && element1.scrollHeight > element1.offsetHeight) {
          element1.scrollTop = element1.scrollHeight
          self.$nextTick(() => {
            element1.scrollTop = element1.scrollHeight
          })
        }
        if (element2 && element2.scrollHeight > element2.offsetHeight) {
          element2.scrollTop = element2.scrollHeight
          self.$nextTick(() => {
            element2.scrollTop = element2.scrollHeight
          })
        }

      }

    },

    selectAnswer (selectAnswer) {
      let self = this
      if (selectAnswer.name !== '我有其他想法') {
        if (selectAnswer.items) {
          self.selectedAns = selectAnswer

          self.chatObj.push({ role: 'self', text: selectAnswer.name })
          self.chatObj.push({ role: 'robot', text: `你想在${selectAnswer.name}做什麼呢？`, options: selectAnswer.items })

          if (self.selectedAns2) {
            self.selectedAns2 = null
          }
        } else {
          if (selectAnswer.pre_name !== self.selectedAns.name) {
            let index = self.chatJSon.findIndex(x => x.name === selectAnswer.pre_name)
            self.selectedAns = self.chatJSon[index]
          }
          self.selectedAns2 = selectAnswer

          self.chatObj.push({ role: 'self', text: selectAnswer.name })
        }
      } else {
        self.$refs.input1.focus()
      }
    },

    inputText () {
      let self = this

      if (self.selfText) {
        if (selectAnswer.items) {
          self.selectedAns = {
            name: self.selfText,
            name_en: self.selfText,
            theme_practical: self.selfText,
            theme_chat: self.selfText
          }

          self.chatObj.push({ role: 'self', text: selectAnswer.name })
          self.chatObj.push({ role: 'robot', text: `你想在${selectAnswer.name}做什麼呢？`, options: selectAnswer.items })

          if (self.selectedAns2) {
            self.selectedAns2 = null
          }
        } else {
          self.selectedAns2 = {
            name: self.selfText,
            name_en: self.selfText,
            theme_practical2: self.selfText,
            theme_chat2: self.selfText,
            context_practical: self.selfText,
            context_chat: self.selfText
          }

          self.chatObj.push({ role: 'self', text: selectAnswer.name })
        }
      }
    },

    inputText2 () {},

    inputText3 () {
      let self = this
      if (self.selfText.trim().length > 0 && self.remainPoints > 0) {
        self.conversationObj.push({ role: 'self', text: self.selfText })

        self.chatsContinue(self.selfText)

        self.selfText = ''

        self.isAiSuggest = false

      } else if (self.remainPoints < 1) {
        self.swal_failPopPoints('點數不足')
      }
    },

    toSlide(i) {
      let self = this
      self.swiper.slideTo(i, 0)

      self.selectedStep = i
    },

    selectType (selectType) {
      let self = this
      if (self.remainPoints > 0) {
        const myName1 = () => {
          return new Promise(async(resolve) => {
            let isStart = await self.swal_startCheck()

            if (isStart) {
              self.isBegin = false

              resolve('isBegin')
            }

          })
        }

        Promise.race([myName1()])
        .then(() => {
          if (selectType === 'record') {
            self.selectedStep = 0
            self.toSlide(0)

            self.generateSentence()
          } else if (selectType === 'practice') {
            self.selectedStep = 1
            self.toSlide(1)

            !self.isGettingChatsCreate ? self.chatsCreate() : null
          }
        })
      } else {
        self.swal_failPopPoints('點數不足')
      }
    },

    onSlideChange () {
      if (this.$refs.mySwiper.swiper.realIndex === 0) {
        if (this.follow_upObj.length < 1 && this.conversationObj.length > 0) {
          this.generateSentence()
        }
      } else {
        if (this.follow_upObj.length > 0 && this.conversationObj.length < 1) {
          this.chatsCreate()
        }
      }
    },

    onPageChange(currentPage) {
      if (currentPage === 0) {
        if (this.follow_upObj.length < 1 && this.conversationObj.length > 0) {
          this.generateSentence()
        }
      } else {
        if (this.follow_upObj.length > 0 && this.conversationObj.length < 1) {
          this.chatsCreate()
        }
      }
    },

    speechCountDown (speechCountDown) {
      this.isSpeechRecognition = speechCountDown.isSpeechRecognition
      this.speechCount = speechCountDown.count
    },

    toggleAISuggest () {
      if (this.lastChatResult && this.remainPoints > 0) {
        this.isAiSuggest = !this.isAiSuggest
      } else if (this.remainPoints < 1) {
        this.swal_failPopPoints('點數不足')
      }
    },

    swal_startCheck () {
      let self = this
      return self.$swal({
        html: `<p>目前總點數：<span style="color: #F30000;">${self.remainPoints}</span> 點</p><p>當進行 AI 口說練習，每次送出後隨即扣除 1 點。</p>`,
        showDenyButton: false,
        focusConfirm: false,
        confirmButtonText: `我知道了`,
        title: 'Notice',
        customClass: {
          title: 'text-white',
          container: '',
          popup: 'p-0',
          header: 'swal-header-notice justify-content-center align-items-center',
          closeButton: '',
          htmlContainer: 'remainPoints_swal my-4',
          actions: 'mb-4 mt-2',
          confirmButton: 'btn-submit',
          cancelButton: 'bg-red-main-ai',
        }
      })
        .then((result) => {
          if (result.isConfirmed === true) {
            return true
          }
        }
      )
    },
    swal_failPopPoints (text) {
      let self = this
      let defaultTitle = text ?  `<h3>${text}</h3><p>請洽客服人員加購點數</p>` : `<h3>生成失敗</h3><p>糟糕！發生了一點小失誤，請再給我一次機會！</p>`
      self.$swal.fire({
        html: defaultTitle,
        showCloseButton: true,
        focusConfirm: false,
        confirmButtonText: `確認`,
        title: 'Notice',
        customClass: {
          title: 'text-white',
          container: '',
          popup: 'p-0',
          header: 'swal-header-notice justify-content-center align-items-center',
          closeButton: '',
          htmlContainer: 'save_check my-4',
          actions: 'mb-4 mt-2',
          confirmButton: 'btn-submit',
          cancelButton: 'bg-red-main-ai',
        }
      })
    },
    swal_failPopUp (text) {
      let self = this
      let defaultTitle = text ?  `<h3>${text}</h3><p>請洽客服人員加購點數</p>` : `<h3>生成失敗</h3><p>糟糕！發生了一點小失誤，請再給我一次機會！</p>`
      self.$swal.fire({
        html: defaultTitle,
        showCloseButton: true,
        focusConfirm: false,
        confirmButtonText: `確認`,
        customClass: {
          container: '',
          popup: 'p-0',
          header: 'swal-header',
          closeButton: '',
          htmlContainer: 'htmlContainer my-3',
          actions: 'mb-4 mt-5',
          confirmButton: 'btn-submit',
          cancelButton: 'bg-red-main-ai',
        }
      })
    },
    getBus () {
      let self = this
      Bus.$on('getDickData', (data) => {
        self.dickData = data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.ai_chat {
  @media (min-width: 640px) {
    padding: 1.5rem;
  }
  .swiper_item {
    min-height: 600px;
    width: 100%;
    @media (min-width: 640px) {
      width: calc(100% / 12 * 8);
      padding: 0.5rem;
    }
  }
  .swiper_rule {
    width: 100%;
    @media (min-width: 640px) {
      width: calc(100% / 12 * 4);
      padding: 0.5rem;
    }
  }
  .opacity-50 {
    opacity: 0.5;
  }
  font-family: x-locale-heading-primary, zillaslab, Palatino, "Palatino Linotype", x-locale-heading-secondary, 'Source Han Serif SC', "STZhongsong", "Source Han Sans CN", 'PingFang SC', 'Myriad Pro', 'Hiragino Sans GB', "SimHei";
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  // padding-left: 5em;
  // padding-right : 5em;
  // @media (max-width: 1024px) {
  //   padding-left: 0;
  //   padding-right : 0;
  // }
  .VueCarousel-navigation-next {
    width: 68px;
    height: 538px;
    background: linear-gradient(272.05deg, #D4D5E1 1.95%, #FFFFFF 42.8%);
  }
  .follow-btn {
    a {
      height: 3.5em;
      &:hover {
        text-decoration: none !important;
      }
    }
  }
}
  .tips-btns {
    top: 0%;
    right: 0;
      padding-top: 82px;
    }
  .chat-content {
    height: calc(60vh);
    overflow-y: scroll;
    overflow-x: hidden;
    @media (max-width: 640px) {
      height: calc(50vh);
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
    }

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    .tips-content {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      .tips-shadow-bg {
        width: 8%;
        background:rgba(0, 0, 0, 0.7);
      }
      .tips-right {
        overflow-y: scroll;
        overflow-x: hidden;
      }
    }
  }
  .ai-suggest-button {
    width: 3rem !important;
    height: 3rem !important;
    margin-right: .25rem !important;
  }
  .chat-button {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;

    .chat-button-input  {
      border-radius: 53px;
      img {
        height: 28px;
        width: auto;
      }
    }
  }

  .keyboard-btn {
    img {
      height: 25px;
      width: auto;
    }
  }
  .notOpenKeyboard {
  }
  .openKeyboard {
    img {
      height: 54px;
      width: auto;
    }
  }
  .microphone-btn {
    background-color: #5A5D9B;
    padding: .8rem 6.5rem;
    border-radius: 53px;
    img {
      height: 26px;
      width: auto;
    }
  }
  .aiSuggestDialogue {
    min-height: 52px;
    @media (max-width: 1024px) {
      min-height: 172px;
    }
  }
</style>
